<template>
  <v-skeleton-loader :loading="loading" height="600" type="table">
    <v-data-table
      :headers="headers"
      :items="list"
      :options.sync="options"
      :loading="loading"
      :server-items-length="totals"
      :footer-props="tableFooterProps"
      @update:items-per-page="searchForms"
      @update:page="onChangePagination"
      class="mt-5"
    >
      <template v-slot:top>
        <v-row>
          <v-col cols sm="6" md="4">
            <v-text-field
              :label="$t('main.form.search')"
              v-model="keySearch"
              outlined
              v-on:keyup.enter="searchForms"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-btn
              class="blue darken-1 white--text mt-2 float-right mr-10 pl-5 pr-5"
              @click="openCreateForm"
              >{{ $t("main.form.createForm") }}</v-btn
            >
          </v-col>
        </v-row>
      </template>
      <template v-slot:[`item.code`]="{ item }">
        <a @click="viewFormDetail(item.id)">{{ item.code }}</a>
      </template>
      <template v-slot:[`item.description`]="{ item }">
        <a @click="viewFormDetail(item.id)">{{ item.description }}</a>
      </template>
      <template v-slot:[`item.Actions`]="{ item }">
        <v-icon small @click="deleteForm(item.id)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
  </v-skeleton-loader>
</template>

<script>
import ManageFormService from "@/components/examination/management/manageForm";
import { TABLE_FOOTER_PROPS } from "@/plugins/constant";
import i18n from "@/plugins/i18n";

export default {
  data() {
    return {
      headers: [
        { text: "ID", value: "id" },
        { text: "Code", value: "code" },
        { text: i18n.t("main.form.description"), value: "description" },
        { text: i18n.t("main.form.amountComponent"), value: "amountComponent" },
        {
          text: i18n.t("main.form.listNameComponent"),
          value: "listComponents",
        },
        { text: i18n.t("main.form.Actions"), value: "Actions" },
      ],
      keySearch: "",
      list: [],
      options: {},
      totals: 0,
      loading: false,
      tableFooterProps: TABLE_FOOTER_PROPS,
    };
  },
  created() {
    this.searchForms();
  },
  methods: {
    showError(message) {
      this.$toast.error(message);
    },
    viewFormDetail(formID) {
      this.$router.push(`/home/form/detail/${formID}`);
    },
    openCreateForm() {
      this.$router.push(`/home/form/create`);
    },
    async searchForms() {
      this.loading = true;
      const { itemsPerPage, page } = this.options;
      var result = await ManageFormService.searchForm(
        this.keySearch,
        page,
        itemsPerPage
      );
      this.loading = false;
      if (!result || result.error) {
        this.showError("Can not get list forms! try again!");
        return;
      }
      var list = result.items.map((item) => {
        let listComponents = item.components.split(",");
        item.amountComponent =
          listComponents == "*" ? "All" : listComponents.length;
        item.listComponents = listComponents.map((i) => {
          i = i.replace(/(\w+)Component/, "$1");
          return i;
        });
        return item;
      });
      this.totals = result.totals;
      this.list = list;
    },
    async deleteForm(formID) {
      var result = await ManageFormService.deleteForm(formID);
      if (result.error) {
        this.showError(
          "This form cannot be deleted form because it had been used!"
        );
        return;
      }
      this.searchForms();
    },
    async onChangePagination(value) {
      this.options.page = value;
      await this.searchForms();
    },
  },
};
</script>

<style>
</style>