import HttpClient from '@/plugins/httpClient'
import { ApiGateway } from '@/plugins/gateway'
import AppConstant from '@/plugins/constant'
var httpClient = new HttpClient(ApiGateway.Emr);

const prefix = '/Ehr/Form';

export default {
  createForm: (bodyReq) => {
    return httpClient.post(`${prefix}/Create`, {}, bodyReq);
  },
  searchForm: (keySearch = "", page = 1, limit = AppConstant.DefaultPaginationLimit) => {
    var query = {
      keySearch,
    }
    var headers = {
      page,
      limit
    }
    return httpClient.get(`${prefix}/Search`, query, headers)
  },
  deleteForm: (formID) => {
    return httpClient.delete(`${prefix}/Delete/${formID}`, {}, {})
  },
  updateForm: (formID, bodyReq) => {
    return httpClient.put(`${prefix}/Update/${formID}`, {}, bodyReq)
  },
  getForm: (formID) => {
    return httpClient.get(`${prefix}/Get/${formID}`, {})
  }
}